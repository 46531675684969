export default function removeBaseUrl(url: string) {
  const replacements = {
    'https://a.storyblok.com': '/storyblok',
    'https://assets.beyondbreath.com': '/assets',
  } as any

  for (const baseUrl in replacements) {
    if (url.startsWith(baseUrl)) {
      return url.replace(baseUrl, replacements[baseUrl])
    }
  }

  return url // return the original URL if no match is found
}
